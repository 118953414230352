import type { Rule } from '@/plugins/casl/ability';
import type { User } from '@/thunder/modules/user/user.model';

export const buildAcl = (_: Partial<User>): Rule[] => {
	return [
		{
			action: 'manage',
			subject: 'all',
		},
	];
};
